import Cookie from 'js-cookie';
import throttle from 'lodash/throttle';
import type { ComponentType, FunctionComponent } from 'react';
import { useEffect, useRef } from 'react';

import type { SectionAndAdProps } from '../components/section';
import { getAdId } from '../helpers';
import { useAdsTracking } from '../hooks/use-ads-tracking';

/**
 * withTracking higher order component
 *
 * this HOC takes care of all the tracking related logic
 * general rules are:
 * to track impression, the ad must be visible for at least 50%
 * and must have been in the viewport for 500ms
 *
 * after the impression was tracked, the click tracking is enabled
 *
 * @param {Component} WrappedComponent
 */

const withTracking = <T extends object>(
  WrappedComponent: ComponentType<T>
): FunctionComponent<T & SectionAndAdProps> => {
  return (props) => {
    if (props.isMockData) {
      return <WrappedComponent {...props} />;
    }

    const box = useRef(null);

    const { trackClick, trackImpression } = useAdsTracking();

    const throttlingRate = 25;
    let clickTracked = false;
    let impressionTracked = false;
    const impressionTrackingDelay = 500;
    let impressionTrackingTimer: unknown = null;

    const scrambledId = props.isLoggedOut ? Cookie.get('s_id2') : undefined;

    const handleClick = (event: React.MouseEvent): void => {
      const isVideoElement = event.target.toString().includes('Video');

      if (props && !isVideoElement) {
        const { sid, trackingToken } = props;

        if (!clickTracked) {
          trackClick(getAdId(sid), trackingToken, scrambledId);
          clickTracked = true;
        }
      }
    };

    const handleImpression = (): void => {
      if (props) {
        const { sid, trackingToken } = props;

        trackImpression(getAdId(sid), trackingToken, scrambledId);
        impressionTracked = true;
        impressionTrackingTimer = null;

        onImpressionCallback?.();
      }
    };

    /**
     * method starts and stops the impression tracking timer
     * @param {bool} isVisible
     */
    const onVisibilityChange = (isVisible: boolean): void => {
      if (isVisible && !impressionTracked) {
        if (!impressionTrackingTimer) {
          // ad is visible and no timer has been started, so start one
          impressionTrackingTimer = setTimeout(
            handleImpression,
            impressionTrackingDelay
          );
        }
      } else {
        if (impressionTrackingTimer) {
          // ad left the viewport, stop the impression timer
          clearTimeout(impressionTrackingTimer as NodeJS.Timeout);
          impressionTrackingTimer = null;
        }
      }
    };

    /**
     * method is fired when the window or container scroll or resize
     */
    const checkVisibility = (): void => {
      const elem: HTMLDivElement | null = box.current;
      // the if() clause is there to avoid errors on server-side rendering
      if (elem) {
        // the XING header is 68px high and positioned fixed
        // which means it blocks the top of the viewport and content
        // scrolls under it.
        // we have to take it into account to get our
        // 50% visible calculations right
        const headerHeight = window.innerWidth < 740 ? 52 : 68; // ψ(｀∇´)ψ

        // the XING footer is 60px high and positioned fixed
        // which means it blocks the bottom of the viewport and content
        // scrolls under it.
        // we have to take it into account to get our
        // 50% visible calculations right
        const footerHeight = window.innerWidth < 740 ? 60 : 0; // ψ(｀∇´)ψ

        // get position of ad DOM node
        // pos is absolute, so it will give the same results on window
        // and inside a scrolling container
        const pos = (elem as HTMLDivElement).getBoundingClientRect();

        // 50% visibility equals half the ad's height
        const halfHeight = pos.height / 2;

        // only continue if something has been actually rendered
        if (halfHeight > 0) {
          // is true if the ad is at least 50% visible below the header
          // but stays true if the ad scrolls out of the viewport on the bottom
          const isVisibleFromTop = pos.top + halfHeight > headerHeight;

          // is true if the ad is at least 50% visible from the bottom
          // but stays true if the ad scrolls out of the viewport on the top
          const isVisibleFromBottom =
            pos.top + halfHeight < window.innerHeight - footerHeight;

          // if both variables above are true, the ad must be in the viewport
          onVisibilityChange(isVisibleFromTop && isVisibleFromBottom);
        } else onVisibilityChange(false);
      }
    };

    useEffect(() => {
      const visibilityTimer = setTimeout(() => checkVisibility(), 500);
      const throttled = throttle(checkVisibility, throttlingRate);

      window.addEventListener('scroll', throttled);
      window.addEventListener('resize', throttled);

      return () => {
        clearTimeout(visibilityTimer);
        window.removeEventListener('scroll', throttled);
        window.removeEventListener('resize', throttled);
      };
    }, []); /* eslint "react-hooks/exhaustive-deps": "off" */

    const wrappedComponentProps = { ...props };

    // Expose callbacks to the wrapped component
    const { onClickCallback, onImpressionCallback } = wrappedComponentProps;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        ref={box}
        onClick={(e) => {
          handleClick(e);
          onClickCallback?.();
        }}
        tabIndex={0}
        role="button"
        className={`${props.sectionId}-wrapper`}
      >
        <WrappedComponent {...wrappedComponentProps} />
      </div>
    );
  };
};

export default withTracking;
